<template>
  <div>
    <van-nav-bar
      style="color: #000000"
      title="用户协议"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="box">
      <p>欢迎您使用“Win生活”软件及相关服务！</p>
      <p>一.导言</p>
      <p style="padding-top: 15px">Win生活是指合肥展雷网络科技有限公司及其关联方合法运营的
        软件、APP、小程序、网站等（以下简称“我们”）向您提供的产品与服务，包括但不限于个性的视频推荐、网络直播、发布信息、互动交流、电商购物、搜索查询等核心功能及其他功能。《Win生活用户协议》（以下简称“本协议”）是您与公司就您下载、安装、注册、登录、
        适用（以下统称“使用”）“Win生活”软件，并获得“Win生活”提供的相关服务所立的协议。</p>
      <p style="padding-top: 15px">为了更好地为您提供服务，请您在开始使用“Win生活”软件及相关服务之前，认真阅读并充分理解本协议，特别是
        涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法
        律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。</p>
      <p style="padding-top: 15px">如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用“Win生活”软件及相关服务。</p>
      <p style="padding-top: 15px">如您不同意本协议，这将导致公司无法为您提供完整的产品和服务，您也可以选择停止使用。如您自主选择同意或使用“Win生活”软件及相关服务，则视为您已充分理解本协议，
      并同意作为本协议的一方当事人接受本协议以及其他与“抖音”软件及相关服务相关的协议和规则（包括但不限于《“Win生活”隐私政策》）的约束。</p>
      <p style="padding-top: 15px">公司有权依“Win生活”软件及相关服务或运营的需要单方决定，安排或指定其关联方、控制公司、继承公司或公司认可的第三方公司继续运营“Win生活”软件。并且，就本协议项下涉及的某些服务，可能会由公司的关联方、控制公司、
        继承公司或公司认可的第三方公司向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。</p>
      <p style="padding-top: 15px">如对本协议内容有任何疑问、意见或建议，您可通过登录“Win生活”客户端内的“反馈与帮助”页面或发送邮件至yidianchengpin@163.com与公司联系。</p>
      <p style="padding-top: 15px">二、Win生活平台服务</p>
      <p style="padding-top: 15px">1.除在APP中明确表明为“自营平台项目”(或类似表述)Win生活仅作为用户提供淘宝(即由浙江淘宝网络有限公司经营的域名为taobao.com的网络零售网站平台)、天猫(即由浙江天猫网络有限公司经营的域名为tmall.com的网络零售网站平台)等购物平台(“外 部电商平台”)中的商家提供产品及产品信息的展示平台。
       Win生活平台为用户提供以下平台服务:</p>
      <p style="padding-top: 15px">(1)为您展示商品或服务Win生活会为您展示商品或服务的相关信息，供您浏览、了解所需的商品或服务。</p>
      <p style="padding-top: 15px">(2)为您推送商品或服务Win生活会为您制定更符合您喜好和需求的个性化推送商品或服务的页面展示和搜索结果，提升您的使用体验。</p>
      <p style="padding-top: 15px">(3)为您在购物平台上购买商品或服务提供优惠通道当您欲购买在Win生活上浏览的商品或服务时，您通过Win生活领取优惠券或其他福利后，直接进入销售该商品或服务的淘宝、天猫店铺和其他商家店铺并完成下单流程。</p>
      <p style="padding-top: 15px">2.Win生活为技术服务平台，用户购买Win生活上展示和推送的商品或者服务时，需按照外部电商平台要求另行签署相关协议，所形成的买卖交易关系是在外部电商平台商家与用户之间建立，所购商品或者服务直接由外
        部电商平台商家向用户提供，包括但不限于商品或者服务的供应、运送、储存、交付、付款、包装、售后服务等。</p>
      <p style="padding-top: 15px">3.用户与外部电商平台商家之间因商品或服务交易发生争议
        或其他 纠纷的，包括但不限于投诉、起诉、举报及税赋等，由用户与外部电商平台商家双方自行解决。经用户申请，Win生活可以为双方提供第三方协助调解纠纷服务。</p>
      <p style="padding-top: 15px">4.使用Win生活或通过Win生活相关链接产生的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购
        买、恶意维权等扰乱Win生活及外部电商平台正常交易秩序的行为。若您严重违背社会公德、提供虚假注册身份信息、经外部电商平台或我们判定认为存在恶意退货或不合常理的高退货率以及其他不符合正常消费习惯的情形、或经判定认为属于损害Win生活及外部平台不正当行为时
        ，基于维护交易秩序及交易安全的需要，Win生活发现上述情形时有权主动执行对订单不予结算、冻结该用户账号等操作。</p>
      <p style="padding-top: 15px">5.为了更好的服务用户，Win生活可能会不定时举办各类活动，如果您选择参加活动，应当认真查阅各类活动另行制定的规则
        。您同 不会采取作弊、利用系统漏洞等手段扰乱Win生活秩序。</p>
      <p style="padding-top: 15px">三、用户账户的注册、使用与注销</p>
      <p style="padding-top: 15px">1.Win生活重视未成年人的安全与个人信息保护，注册Win生活的用户应是具备完全民事行为能力和民事权利能力的自然人。用户一旦注册成功，我们将推定您具有相应的民事行为能力。若您不具备前述与您行为
        相适应的民事行为能力， 则您及您的监护人应依照法律规定承担因使用本APP而可能产生的一切法律后果。</p>
      <p style="padding-top: 15px">2.用户应提供本人正在合法有效使用的手机号码进行“Win生活”账号注册，并且您同意Win生活将您手机号码及手机设备识别码等作为用户身份识别信息。该号码是我们与您联系的重要渠道，如发生变更，应
        及时通知我们。否则，可能会因无法有效联系到您，或无法及时处理与您相关的争议等事项而影响您的相关权益。</p>
      <p style="padding-top: 15px">3.为向您提供更细致、贴心的服务，您允许Win生活及授权、认可的第三方向您注册账号时填写的手机号码推送您可能感兴趣的
        广告宣传信息、促销优惠等商业性信息短信;如您不需要此类信息服务，您有权根据短信提供的相应退订方式进行退订。</p>
      <p style="padding-top: 15px">4.用户注册账号所提供和使用的信息应当真实、有效、合法。注册成功后，Win生活将为您配置账户，该账户之所有权Win生活所有，您在本协议有效期内仅享有使用权。由于账户关联用户的个人信息及
        Win生活商业信息，该账户仅限您本人使用，未经Win生活书面同意，您不得出借、出租或作其它形式的转让。</p>
      <p style="padding-top: 15px">5.Win生活有权根据法律法规或政策规定，或根据自行制定的规范或规则，对违规、不当使用账户等行为予以限制或予以注销，如Win生活判断您的账户可能涉及信息泄漏及/或影响清达云商
        城信息安全或者正常运营的及/或侵害或影响其他用户权益的，Win生活可拒绝向您提供服务或终止本协议。</p>
      <p style="padding-top: 15px">6.用户可以申请注销已注册的账户。如果您需要注销您的帐户，我们将在核实您的身份、要求您完成未完成的交易、完成资金结算等(例如已充值或已获得的虚拟资产等)后，为您提供帐户注销服务。在账户注销完成后，Win生活不在为您提供任何服务。账户注销后，您的个人信息我们会使其在前台系统保持不可检索、访问的状态，或对
        其进行匿名化处理。您知晓，根据法律法规规定，相关交易信息保存时间应自交易完成之日起不少于三年。</p>
      <p style="padding-top: 15px">7.为使您更好地使用Win生活的各项服务，并保障您的账户安全，我们将按照相关法律法规规定要求您完成实名认证。如涉及第三方支付、资金结算或其他可能需要您提供银行卡信息的业务，您应当保证提供的银行帐户信息或第三方支付帐户信息真实、有效、合法，且属于您本人所有，我们及关联公司或我们合作的 第三方支付机构将根据您提供的银行帐户信息或第三方支付帐户信息与您进行 资金结算。您提供的银行帐户信息或第三方支付帐户信息错误或您拒绝提供，您 应当理解您将无法使用本 APP 部分功能、第三方支付机构 提供的支付功能以及 您将无法获得结算资金。您理解并同意如因您未能提供有效的上述信息导致资金 无法结算时，该部分资金不会因为未支付给您而产生利息。我们
        可以向您发送通知以便于您可以及时提供信息，若超出一定时间未得到回复，我们有权收回该部分结算资金自行处理。</p>
      <p style="padding-top: 15px">8.如发现任何未经您授权使用您的账户登录Win生活的情形，我们建议您第一时间与我们联系，但您应理解Win生活对任何请求采取行动均需要合理时间，且Win生活所采取的行动可能无法避免或者阻
        止侵害结果的形成或者扩大，除Win生活存在法定过错外，Win生活不对该侵害结果及扩大承担任何责任。</p>
      <p>四、用户个人信息收集、使用与保护</p>
      <p>1.详见《隐私协议》。</p>
      <p>2.《隐私协议》是本协议正文的主要组成部分，与本协议共同约束用户与Win生活</p>
      <p style="padding-top: 15px">3.Win生活接入第三方SDK初衷是为了向用户提供更成熟、优质的平台技术服务，有关服务可能会涉及到个人信息收集，在收集用户信息之前，Win生活会征求您的授权同意。如您对此存在疑问，您可以通过
      附件链接查阅第三方SDK发布的隐私协议。您的明示授权或继续使用，将视为您同意信息的合理采集以及相关的隐私协议。</p>
      <p style="padding-top: 15px">1.用户在注册、使用Win生活过程中制作、复制、发布、上传、传播的任何形式的内容，包括但不限于账号头像、昵称、标签、地址等注册信息及认证资料，或以文字、语音、图片、视频、图文等任何形式发送、回复或自动回复的消息、相关链接页面以及其他使用账号或 本服务所产生的内容均不得违反现行法律、法规、规章、政策规定。 2. 如果用户在境外注册、使用Win生活，应同时遵守所在地或相关 国家(地区)的法律法规。
          3. 用户不得利用“Win生活”账号或提供的服务以任何形式制作、复制、发布、上传、传播如下法律、法规和政策禁止的内容:</p>
      <p>(1) 反对宪法所确定的基本原则的;</p>
      <p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;</p>
      <p>(3) 损害国家荣誉和利益的;</p>
      <p>(4) 煽动民族仇恨、民族歧视，破坏民族团结的;</p>
      <p>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的;</p>
      <p>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的;</p>
      <p>(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;</p>
      <p>(8) 侮辱或者诽谤他人，侵害他人合法权益的;</p>
      <p>(9) 含有法律、行政法规禁止的其他内容的信息。</p>
      <p> 4.用户不得利用“Win生活”账号或服务以任何形式制作、复制、发布、上传、传播如下干扰“Win生活”正常运营，以及侵犯其他用户或第三方合法权益的内容: </p>
      <p>(1) 含有任何性或性暗示的;</p>
      <p>(2) 含有辱骂、恐吓、威胁内容的;</p>
      <p>(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的;</p>
      <p>(4) 涉及他人隐私、个人信息或资料的;</p>
      <p>(5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的;</p>
      <p>(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
      <!-- 二 -->
      <p>5.用户不得利用“Win生活”账号或服务进行如下行为:</p>
      <p>(1)提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的;</p>
      <p>(2)虚构事实、隐瞒真相以误导、欺骗他人的;</p>
      <p>(3)利用技术手段批量建立虚假账号的;</p>
      <p>(4)利用“Win生活”账号或服务从事任何违法犯罪活动的;</p>
      <p>(5)制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的;</p>
      <p>(6)其他违反法律法规规定、侵犯其他用户合法权益、干扰“Win生活”正常运营或Win生活未明示授权的行为。</p>
      <p>6.用户不得利用或针对Win生活及相关服务进行任何危害计算机网络安全、危害社会经济秩序的相关行为，如果我们有理由认为您的行为违反或可能违反上述
        约定的，我们可独立进行判断并处理，且在任何时候有权在不事先通知的情况下终止向您提供服务，并依法追究相关责任。</p>
      <p>7.用户不得以Win生活的名义传送或发布信息;也不得以易被公众或第三方混淆为Win生活官方意见的方式
        传送或发布信息。用户在Win生活传送、发布的任何内容并不反映或代表，也不得被视为反映或代表Win生活的观点、立场;用户须保证
        其通过“Win生活”账号或服务所传送的信息真实、合法、无害、准确、有效。如因用户传送的信息违法、给Win生活或第
        三方造成损害的，用户应当依法予以赔偿，Win生活有权依据法律法规、政策、本协议条款对相关违法、错误信息作出的处理。</p>
      <p>8.经许可后，用户、第三方对本APP软件及相关服务的信息内容的分享、转发等行为，应符合以下规范:</p>
      <p>(1)对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经公司事先书
        面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人;</p>
      <p>(2)不得对本APP软件及相关服务的源网页进行任何形式的任何改动，包括但不限于本APP软件及相关服务的首页链接、广告系统链接等入口，也
        不得对本APP软件及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍;</p>
      <p>(3)不得把相关数据内容用于公司许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用。</p>
      <p>(4)用户向任何第三人分享、转发、复制本APP软件及相关服务信息内容的行为，还应遵守公司为此制定的其他规范和标准。</p>
      <p>9.未经Win生活许可，任何人不得以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式直接或间接盗取本APP软件及相关服务的视频、图
        文等信息内容，或以任何方式(包括但不限于隐藏或者修改域名、平台特有标识、用户名等)删除或改变相关信息内容的权利管理电子信息。
        Win生活等文字及/或标识，以及其他标识、徽记、产品和服务名称均为Win生活所有，如有宣传、展示等任何使用需要，您必须取得Win生活事先书面授权。</p>
      <p>五违规用户的处理</p>
      <p>1.如果Win生活发现用户有违反法律法规、政策规定、“外部电商平台”管理规定、本协议条款约定，或被其他用户投诉具有违反法律法
        规、政策规定、本协议条款约定的行为，Win生活有权依据法律法规、政策及本协议条款对用户的违法、错误的行为或内容进行处理，并视情节轻重对违规的用户处以包括但不限于警告、账号封禁、功
        能封禁、账户冻结、不予结算资金等处罚，并将有关违法信息向有关部门报告，用户应承担由此产生的一切法律后果。</p>
      <p>2.Win生活对上述违规内容的违规内容的审查仅为初步表面审查，不对审查结论承担责任;如果用户对处理结果存在异议，则应提供相应的证明文件，并与我们或投诉方沟通或采取法律途径解决争议。</p>
      <p>3.因用户违反法律法规、政策规定、本协议约定或发生其它过错，导致任何第三方向Win生活或其合作方、关联方主张
        任何索赔、要求的，用户应承担相关责任。如果因此造成Win生活或其合作方、关联方承担相关责任或发生其他任何损失的，包括律师费、
        调查取证费、电子数据公证费、差旅费等，Win生活或其合作方、关联方有权要求该用户承担赔偿责任或者向该用户追偿。</p>
      <p>4.被处理用户如对投诉内容或处理决定有异议，可提交申诉意见，Win生活将对申诉进行审查，并自行合理判断决定是否变
        更处罚措施。对于因投诉所导致的处理，Win生活仍有权根据投诉方与
        用户共同确定的意见或司法机关生效法律文书增加、变更或撤销对用户的处理。</p>
      <p>六本协议的生效、变更与终止</p>
      <p>1.本协议自您勾选并点击“我已阅读并同意”本协议时即生效。如未点击确认本协议之前事实上已在使用本平台功能的，则本协议在您的事实行为发生之时生效。</p>
      <p>2.由于互联网行业发展迅速、APP及服务频繁迭代更新等特点，我们可能会对相关的产品或服务进行升级优化，您理解并同意我们会根据商业需要，在符合法律规定的情况下对本
        协议的部分条款进行更新，更新后的协议条款将代替原来的协议并在正式发布之日起生效，您可以在相关服务页面查阅最新版本的协议条款。</p>
      <p>3.如本协议后续发生变更，我们在APP公布更新后的协议，该公布行为视为我们已通知您更新内容。我们也可能会采用本协议约定的其
        他通知方式通知您。如您对更新后的用户协议有任何疑问，您可通过本协议提及的联系邮箱向Win生活工作人员反馈，我们会及时为
        您解答。如您不同意更新后的协议，您可以停止使用本APP,本协议修改后，如果您继续使用本APP的，即视为您已接受修改后的协议。</p>
      <p>4.存在以下任一情形的，本协议在用户与Win生活之间终止。因终止本协议给对方或其他第三方合法权益造成损失的，有权要求对造成的损失承担法律责任:</p>
      <p>(1)用户账号注销的，本协议自注销之日起在用户和Win生活之间终止;</p>
      <p>(2)因违反法律法规、政策规定或本协议约定导致本协议终止的;</p>
      <p>(3)因任一方违反本协议约定、发生其他违法或不当行为导致本协议终止的;</p>
      <p style="margin-top:15px">(4)发生其他导致本协议终止的情形。</p>
      <p>5.本协议终止时，Win生活有权要求您就您账户下未完成的交易、未支付的订单、未结算的资金等进行处理
        ，若您在合理时间内未处理，Win生活有权以合理的方式代为处理，并就处理过程中可能带来损失的向您追偿。
        第七条争议解决条款本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。
        若用户和Win生活之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交Win生活住所地有管辖权的人民法院管辖。</p>
      <p>第八条不可抗力条款</p>
      <p>1.“不可抗力”是指本协议双方不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误任何一方根据本协议履行
        其全部或部分义务。该事件包括但不限于政府行为、自然灾害、战争或任何其他类似事件。</p>
      <p>2.遭受不可抗力事件的一方可暂行中止履行本合同项下的义务直至不可抗力事件的影响消除为止
        并且无需为此而承担违约责任;但应尽最大努力克服该事件，减轻负面影响。</p>
      <p>第九条其他条款</p>
      <p>1.除非我们另行声明，Win生活的所有产品、技术、软件、程序、数据及其他信息(包括文字、图标、图片、照片、音频、视频、图表、
         色彩组合、版面设计等)的所有权利(包括版权、商标权、专利权、商业秘密及其他相关权利)均归Win生活所有。</p>
      <p>2.用户理解并接受:本协议的正文还包括《隐私协议》【用户可点击附件链接，查看详细内容】，与本协议具有同等法律效力，共同约束用户与Win生活。</p>
      <p>3.本协议的任何条款不论因何种原因无效或不具可执行性，不影响其余条款在用户与Win生活双方之间的法律效力。</p>
      <p>4.如您对本协议条款有任何疑问或者需要帮助，可通过yidianchengpin@163.com与我们联系。</p>
      <p>再次郑重提醒:Win生活用户请特别注意本协议中加粗、加下划线突出显示的条款内容显著款，请仔细阅读并自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</p>
    </div>
  </div>
</template>

<script>
// import { Toast } from "vant";
export default {
  methods: {
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        // close
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
p {
  // padding: 0px 20px;
  margin: 0 15px;
}
.box {
  padding-top: 65px;
  background-color: #f6f6f6;
}
</style>
